.box{
	font-family: "Righteous";
	background-color: #fdfad6;
	box-shadow: none;
	border-style: solid;
	border-width: 6px;
	border-color: #eeae2c;
	border-radius: 24px;
	box-shadow: 0px 0px 0px 6px #bc5626;
}

.title{
	font-family: "Righteous";
	color: #563128;
}

.subtitle{
	font-family: "Righteous";
	color: #563128;
}